<template>
  <div class="iq-sidebar" id="blockleft">
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-menu-line"></i></div>
          </div>
        </div>
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
</template>
<script>
import List from "../menus/ListStyle1";
var $ = require("jquery");
export default {
  name: "SideBarStyle1",
  props: {
    homeURL: { type: Object, default: () => ({ name: "layout1.dashboard" }) },
    items: { type: Array },
    logo: { type: String, default: require("../../../assets/images/logo.png") },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
  },
  components: {
    List,
  },
  methods: {
    miniSidebar() {
      this.$emit("toggle");
    },
  },
  data() {
    return {};
  },
  mounted() {
    function sticky_relocate() {
      var containerWidth = $(".container").outerWidth(true);

      var window_top = $(window).scrollTop();
      var div_top = $("#sticky-anchor").offset().top;
      if (window_top > div_top) {
        $("#blockleft").addClass("sticky-topl");
        $("#blockright").addClass("sticky-topl");

        // $('#blockright').css("sticky", (containerWidth-800)/2 + "px");
      } else {
        $("#blockleft").removeClass("sticky-topl");
        $("#blockright").removeClass("sticky-topl");
      }
    }
    $(function() {
      $(window).scroll(sticky_relocate);
      sticky_relocate();
    });

    $(".input").focus(function() {
      $("#search").addClass("move");
    });
    $(".input").focusout(function() {
      $("#search").removeClass("move");
      $(".input").val("");
    });

    $(".fa-search").click(function() {
      $(".input").toggleClass("active");
      $("#search").toggleClass("active");
    });
  },
};
</script>

<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar bg-blue shadow-none sticky sm-p-0" id="myHeader">
    <div class="container-fluid p-0">
      <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0 sm-float-right" style="height: 75px">
          <div class="iq-search-bar" style="position:relative">
            <form action="#" class="searchbox">
              <input type="text" class="text search-input" v-model='searchQuery' placeholder="Type here to search user..." @keyup="searchUser">
              <a class="search-link" href="#"><i class="ri-search-line text-white"></i></a>
            </form>
            <span v-if="showBox" >
              <span v-if='searchUserList.length > 0' style= 'position:absolute;overflow-y: scroll; height:200px;top:60px;left:0;background-color:white;width:100%'>
            <ul  v-for="user in searchUserList" :key="user.id" >
                 <li v-on:click="showBox = !showBox" v-on-clickaway="away"><router-link :to="{ name: 'user.profile', params: { userId: user.id }}" class="iq-waves-effect d-flex align-items-center "><img v-if="user.avatar"  :src="user.avatar" class="rounded-circle mr-3 sm-mr-1" alt="user" style="width:30px">
              <img v-else  src="../../../assets/images/user-icon.svg" class=" rounded-circle mr-3 sm-mr-1" alt="user" style="width:30px">
                <div class="caption">
                  <h6 class="mb-0 line-height">{{user.name}}</h6>
                </div>
                </router-link>
              </li>
            </ul>
            </span>
            </span>
            
          </div>
          <div class="bg-blue d-flex" id="navbarSupportedContent1">
            <ul class="navbar-nav navbar-list">
              <li><router-link to="/profile" class="iq-waves-effect d-flex align-items-center "><img v-if="avatar"  :src="avatar" class="img-fluid rounded-circle mr-3 sm-mr-1" alt="user">
              <img v-else  src="../../../assets/images/user-icon.svg" class="img-fluid rounded-circle mr-3 sm-mr-1" alt="user">
                <div class="caption">
                  <h6 class="mb-0 line-height text-white">{{name}}</h6>
                </div>
                </router-link></li>
              <li> <router-link to='/' class="iq-waves-effect d-flex align-items-center text-white">
               <i class="ri-home-line"></i> </router-link> </li>
              <li class="nav-item"> <a href="#" class="search-toggle iq-waves-effect">
                <div id="lottie-beil">
                  <i class="fa fa-bell-o" aria-hidden="true" style="color:white"></i>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 192 192" width="192" height="192" preserveAspectRatio="xMidYMid meet" style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px);">
                    <defs>
                      <clipPath id="__lottie_element_2">
                        <rect width="192" height="192" x="0" y="0"></rect>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#__lottie_element_2)">
                      <g transform="matrix(5.833330154418945,0,0,5.833330154418945,26.000038146972656,22.02753448486328)" opacity="1" style="display: block;">
                        <g opacity="1" transform="matrix(1,0,0,1,12,21.639999389648438)"></g>
                        <g opacity="1" transform="matrix(0.9631125330924988,0.2690989673137665,-0.2690989673137665,0.9631125330924988,12,8.654000282287598)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M6,-0.6539999842643738 C6,-5.052999973297119 1.2649999856948853,-8.345999717712402 -3.38700008392334,-5.715000152587891 C-5.052000045776367,-4.77400016784668 -6.0329999923706055,-2.9560000896453857 -6.002999782562256,-1.0440000295639038 C-5.888999938964844,6.271999835968018 -9,8.345999717712402 -9,8.345999717712402 C-9,8.345999717712402 9,8.345999717712402 9,8.345999717712402 C9,8.345999717712402 6,6.3460001945495605 6,-0.6539999842643738z"></path>
                        </g>
                        <g opacity="1" transform="matrix(0.9711591601371765,0.2384321689605713,-0.2384321689605713,0.9711591601371765,12.76797103881836,22.506498336791992)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M1.7300000190734863,-0.6399999856948853 C1.1749999523162842,0.3149999976158142 -0.04800000041723251,0.6399999856948853 -1.003999948501587,0.08699999749660492 C-1.3049999475479126,-0.08799999952316284 -1.555999994277954,-0.33799999952316284 -1.7300000190734863,-0.6399999856948853"></path>
                        </g>
                      </g>
                    </g>
                  </svg> -->
                </div>
                <span class="dots bg-white" v-if="notifications.length > 0" ></span> 
                </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">All Notifications<small class="badge  badge-light float-right pt-1">{{notifications.length}}</small></h5>
                      </div>
                      <div  v-for="notif in notifications" :key="notif.id">
                        <router-link to="/notification" class="iq-sub-card">
                        <div class="media align-items-center">
                          <div class=""> 
                            <img v-if='notif.img' class="avatar-40 rounded" :src="notif.img" alt=""> 
                             <img v-else class="avatar-40 rounded" src="../../../assets/images/user-icon.svg" alt="">
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{notif.heading}}</h6>
                            <!-- <small class="float-right font-size-12">{{notif.time}}</small> -->
                            <p class="mb-0">{{notif.time}}</p>
                          </div>
                        </div>
                        </router-link> 
                      </div>
                      </div>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown"> <a href="#" class="search-toggle iq-waves-effect">
                <div id="lottie-mail">
                  <i class="fa fa-envelope-o" style="color:white" aria-hidden="true"></i>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" width="192" height="192" preserveAspectRatio="xMidYMid meet" style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px);">
                    <defs>
                      <clipPath id="__lottie_element_7">
                        <rect width="192" height="192" x="0" y="0"></rect>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#__lottie_element_7)">
                      <g transform="matrix(6.663766860961914,0.4056459665298462,-0.4056459665298462,6.663766860961914,20.902542114257812,32.17888641357422)" opacity="1" style="display: block;">
                        <g opacity="1" transform="matrix(1,0,0,1,12,12.399999618530273)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M8.20199966430664,-6.318999767303467 C8.682999610900879,-6.021999835968018 9,-5.51800012588501 9,-4.949999809265137 C9,-4.949999809265137 9,4.949999809265137 9,4.949999809265137 C9,5.857999801635742 8.190999984741211,6.599999904632568 7.199999809265137,6.599999904632568 C7.199999809265137,6.599999904632568 -7.199999809265137,6.599999904632568 -7.199999809265137,6.599999904632568 C-8.1899995803833,6.599999904632568 -9,5.857999801635742 -9,4.949999809265137 C-9,4.949999809265137 -9,-4.949999809265137 -9,-4.949999809265137 C-9,-5.4730000495910645 -8.730999946594238,-5.940999984741211 -8.314000129699707,-6.24399995803833"></path>
                        </g>
                        <g opacity="1" transform="matrix(1,0,0,1,12,9.75)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M7.98799991607666,-3.881999969482422 C7.98799991607666,-3.881999969482422 0,-10.239999771118164 0,-10.239999771118164 C0,-10.239999771118164 -7.98799991607666,-3.8450000286102295 -7.98799991607666,-3.8450000286102295"></path>
                        </g>
                      </g>
                      <g style="display: block;" transform="matrix(6.663766860961914,0.4056459665298462,-0.4056459665298462,6.663766860961914,22.10447120666504,12.43414306640625)" opacity="1">
                        <g opacity="1" transform="matrix(1,0,0,1,12,15.399999618530273)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M-7.199999809265137,-6.599999904632568 C-7.199999809265137,-6.599999904632568 7.199999809265137,-6.599999904632568 7.199999809265137,-6.599999904632568 C8.190999984741211,-6.599999904632568 9,-5.85699987411499 9,-4.949999809265137 C9,-4.949999809265137 9,4.949999809265137 9,4.949999809265137 C9,5.857999801635742 8.190999984741211,6.599999904632568 7.199999809265137,6.599999904632568 C7.199999809265137,6.599999904632568 -7.199999809265137,6.599999904632568 -7.199999809265137,6.599999904632568 C-8.1899995803833,6.599999904632568 -9,5.857999801635742 -9,4.949999809265137 C-9,4.949999809265137 -9,-4.949999809265137 -9,-4.949999809265137 C-9,-5.85699987411499 -8.1899995803833,-6.599999904632568 -7.199999809265137,-6.599999904632568z"></path>
                        </g>
                        <g opacity="1" transform="matrix(1,0,0,1,12,7.5)">
                          <path fill="rgb(255,255,255)" fill-opacity="1" d=" M7.01200008392334,2.9149999618530273 C7.01200008392334,2.9149999618530273 7.012263298034668,-4.675558090209961 7.012263298034668,-4.675558090209961 C7.012263298034668,-4.675558090209961 -6.987736701965332,-4.675558090209961 -6.987736701965332,-4.675558090209961 C-6.987736701965332,-4.675558090209961 -6.98799991607666,2.9149999618530273 -6.98799991607666,2.9149999618530273"></path>
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M7.01200008392334,2.9149999618530273 C7.01200008392334,2.9149999618530273 7.012263298034668,-4.675558090209961 7.012263298034668,-4.675558090209961 C7.012263298034668,-4.675558090209961 -6.987736701965332,-4.675558090209961 -6.987736701965332,-4.675558090209961 C-6.987736701965332,-4.675558090209961 -6.98799991607666,2.9149999618530273 -6.98799991607666,2.9149999618530273"></path>
                        </g>
                        <g opacity="1" transform="matrix(1,0,0,1,12,12.75)">
                          <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="2" d=" M8,-3.1500000953674316 C8,-3.1500000953674316 0,3.1500000953674316 0,3.1500000953674316 C0,3.1500000953674316 -8,-3.1500000953674316 -8,-3.1500000953674316"></path>
                        </g>
                      </g>
                    </g>
                  </svg> -->
                </div>
                <span class="count-mail bg-white" v-if="msgNotifications.length > 0"></span> </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div  class="bg-primary p-3">
                        <h5 class="mb-0 text-white">All Messages<small class="badge  badge-light float-right pt-1">{{msgNotifications.length}}</small></h5>
                      </div>
                      <div v-for="notif in msgNotifications" :key="notif.id">
                      <router-link to="/app/chat" class="iq-sub-card">
                      <div class="media align-items-center">
                        <div class=""> 
                          <img v-if='notif.img' class="avatar-40 rounded" :src="notif.img" alt=""> 
                          <img v-else class="avatar-40 rounded" src="../../../assets/images/user-icon.svg" alt="">
                          </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{notif.heading}}</h6>
                          <small class="float-left font-size-12">{{notif.time}}</small> </div>
                      </div>
                      </router-link>
                      </div>
                      </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="navbar-list">
                  <li>
                    <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center text-white pr-0 sm-pr-small">
                    <i class="ri-arrow-down-s-fill"></i>
                    </a>
                    <div class="iq-sub-dropdown iq-user-dropdown">
                        <div class="iq-card shadow-none m-0">
                          <div class="iq-card-body p-0 ">
                              <div class="bg-primary p-3 line-height">
                                <h5 class="mb-0 text-white line-height">Hello {{name }}</h5>
                                <span class="text-white font-size-12">Available</span>
                              </div>
                              <router-link to="/profile" class="iq-sub-card iq-bg-primary-hover">
                                <div class="media align-items-center">
                                    <div class="rounded iq-card-icon iq-bg-primary">
                                      <i class="ri-file-user-line"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0 ">My Profile</h6>
                                      <p class="mb-0 font-size-12">View personal profile details.</p>
                                    </div>
                                </div>
                              </router-link>
                              <router-link to="/user/profile-edit"  class="iq-sub-card iq-bg-warning-hover">
                                <div class="media align-items-center">
                                    <div class="rounded iq-card-icon iq-bg-warning">
                                      <i class="ri-profile-line"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0 ">Edit Profile</h6>
                                      <p class="mb-0 font-size-12">Modify your personal details.</p>
                                    </div>
                                </div>
                              </router-link>
                            <div class="d-inline-block w-100 text-center p-3">
                                <a class="bg-primary iq-sign-btn" href="javascript:void(0)" role="button" @click="handleLogout">Sign out<i class="ri-login-box-line ml-2"></i></a>
                              </div>
                          </div>
                        </div>
                    </div>
                  </li>
              </ul>
          </div>
          <div class="search-menu ">
            <div class="btn"> <i class="fa fa-search" aria-hidden="true"></i> </div>
            <div class="form">
              <input type="text" class="input active" placeholder="Type here to search user...">
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarItems from '../../../FackApi/json/SideBar'
import { mapGetters } from 'vuex'
import axios from '../../../components/axios'
import { mixin as clickaway } from 'vue-clickaway';
 
export default {
  name: 'NavBarStyle2',
  mixins: [ clickaway ],
  props: {
    homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
    title: { type: String, default: 'Dashboard' },
    logo: { type: String, default: require('../../../assets/images/wfd-logo.svg') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  created() {
   setInterval(() => this.getNavNotification(), 10000);
   setInterval(() => this.getMsgNotification(), 10000);
  },
 
 async mounted () {
   var parent = this;
   axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");
    document.addEventListener('click', this.closeSearch, true)


    var userData = await JSON.parse(localStorage.getItem('user'));
    this.name = userData.name
    this.avatar = userData.avatar

    
    this.$root.$on('user', data => {
        this.name = data.name
        this.avatar = data.avatar
    });
    

    const config = {
      method: "get",
      url: "getunreadnotification",
    };

    axios(config).then(res => {
      parent.notifications = res.data.notifications

    }).catch(err => {
        console.log(err);
    })

    const config1 = {
      method: "get",
      url: "msgnotification",
    };

    axios(config1).then(res => {
      parent.msgNotifications = res.data.msgNotifications

    }).catch(err => {
        console.log(err.response);
    })
  },
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      searchQuery:null,
      showBox:false,
      sidebar: SideBarItems,
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      name:'',
      avatar:'',
      searchUserList:[],
      userFriendRequest: [
        {
          img: require('../../../assets/images/user/05.jpg'),
          name: 'Jaques Amole',
          friend: '40  friends'
        },
        {
          img: require('../../../assets/images/user/06.jpg'),
          name: 'Lucy Tania',
          friend: '12  friends'
        },
        {
          img: require('../../../assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0  friends'
        },
        {
          img: require('../../../assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends'
        }

      ],
      notifications:[],
      msgNotifications:[]
    }
  },
  methods: {
    getNavNotification(){
      
       const config = {
          method: "get",
          url: "getunreadnotification",
        };

        axios(config).then(res => {
          parent.notifications = res.data.notifications
        }).catch(err => {
            console.log(err);
        })
    },
    getMsgNotification(){
      var parent = this;

      const config1 = {
        method: "get",
        url: "msgnotification",
      };

      axios(config1).then(res => {
        parent.msgNotifications = res.data.msgNotifications

      }).catch(err => {
          console.log(err.response);
      })
    },
    miniSidebar () {
      this.$emit('toggle')
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
    },
    handleLogout(){
      const config = {
          method: "get",
          url: "logout",
      };

       axios(config).then((response) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // this.$store.commit('setAuthentication',true)
        this.$router.replace('/auth/sign-in1')

       }).catch(function(error) {
        console.log(error);
      });
      
    },
    away: function() {
     this.showBox = false;
    },
    searchUser(){
      this.searchUserList  = [];
      clearTimeout(this.timeout);
      var parent = this;
      if(this.searchQuery !== ''){
         this.timeout = setTimeout(function () {
         
          var config = {
            method: "get",
            url: 'searchUser',
            params: {
                searchQuery: parent.searchQuery,
            },
          };

          
            axios(config).then(res => {
              parent.showBox = true;
              parent.searchUserList = res.data.usersList;
            }).catch(err => {
                console.log(err.response);
            })
          
          
      }, 1000);
      }
     
    }
  }
}
</script>

<template>
  <!-- Footer -->
  <footer class="bg-white iq-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <ul class="list-inline mb-0">
              <slot name="left"></slot>
          </ul>
        </div>
        </div>
    </div>

  </footer>
  <!-- Footer END -->
</template>

<script>

export default {
  name: 'FooterStyle1'
}
</script>
